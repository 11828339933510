<script>
import VueRecaptcha from 'vue-recaptcha'
import { sendFeedback } from '@/api'
import { containEmail } from 'epmc-patterns/helpers'
import { addScript } from '@/helpers/load-script-and-style'
import { Action, Loading } from 'epmc-patterns/components/v2'

// const MAX_FILE_NUMBER = 5
// const MAX_FILE_SIZE = 1000 // in kb
const MAX_PAGE_URL_NUMBER = 5

export default {
  components: { Action, Loading, VueRecaptcha },
  props: {
    pageUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputs: {
        name: '',
        email: '',
        message: '',
        // files: [],
        pageUrls: [],
        joinUserResearch: '',
        agreePrivacyNotice: false,
        recaptcha: '',
      },
      inputErrorMessages: {
        name: [],
        email: [],
        message: [],
        // files: [],
        pageUrls: [],
        agreePrivacyNotice: [],
        recaptcha: [],
      },
      networkErrorMessage: '',
      // showAddFiles: false,
      showAddPageUrls: false,
      sendingEmail: false,
    }
  },
  // used for two-way bindings
  watch: {
    'inputs.name': function () {
      this.inputErrorMessages.name = []
    },
    'inputs.email': function () {
      this.inputErrorMessages.email = []
    },
    'inputs.message': function () {
      this.inputErrorMessages.message = []
    },
    'inputs.agreePrivacyNotice': function () {
      this.inputErrorMessages.agreePrivacyNotice = []
    },
  },
  async mounted() {
    await this.$nextTick()
    await addScript({
      src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',
      async: '',
      defer: '',
    })
  },
  methods: {
    // updateFilesErrorMessages() {
    //   const {
    //     inputs: { files },
    //     inputErrorMessages: { files: filesErrorMessages },
    //   } = this

    //   filesErrorMessages.length = 0
    //   if (files.length > MAX_FILE_NUMBER) {
    //     filesErrorMessages.push(
    //       `Maximum number of images is ${MAX_FILE_NUMBER}.`
    //     )
    //   }
    //   if (files.find((file) => file.size > MAX_FILE_SIZE * 1000)) {
    //     filesErrorMessages.push(`Maximum image size is ${MAX_FILE_SIZE}kb.`)
    //   }
    // },
    updateUrlsErrorMessage() {
      const {
        inputs: { pageUrls },
        inputErrorMessages: { pageUrls: pageUrlsErrorMessages },
      } = this

      pageUrlsErrorMessages.length = 0
      if (pageUrls.length > MAX_PAGE_URL_NUMBER) {
        pageUrlsErrorMessages.push(
          `Maximum number of URLs is ${MAX_PAGE_URL_NUMBER}.`
        )
      }
    },
    // dragFiles(e) {
    //   this.inputs.files.push(...e.dataTransfer.files)
    //   this.updateFilesErrorMessages()
    // },
    // onUploadFilesButtonClicked() {
    //   document.getElementById('upload-file-button').click()
    // },
    // uploadFiles(e) {
    //   this.inputs.files.push(...e.target.files)
    //   this.updateFilesErrorMessages()
    // },
    // removeFile(indexToRemove) {
    //   this.inputs.files = this.inputs.files.filter(
    //     (file, index) => index !== indexToRemove
    //   )
    //   this.updateFilesErrorMessages()
    // },
    addPageUrl() {
      const input = document.getElementById('page-url-input').value.trim()
      if (input) {
        this.inputs.pageUrls.push(input)
        document.getElementById('page-url-input').value = ''
        this.updateUrlsErrorMessage()
      }
    },
    removeUrl(indexToRemove) {
      this.inputs.pageUrls = this.inputs.pageUrls.filter(
        (url, index) => index !== indexToRemove
      )
      this.updateUrlsErrorMessage()
    },
    async submit() {
      // no need for files and urls
      const {
        inputs: {
          name,
          email,
          message,
          pageUrls,
          joinUserResearch,
          agreePrivacyNotice,
          recaptcha,
        },
        inputErrorMessages,
        close,
        pageUrl,
        onRecaptchaExpired,
      } = this

      this.networkErrorMessage = ''

      if (!name) {
        inputErrorMessages.name = ['Please enter name.']
      }
      if (!containEmail(email)) {
        inputErrorMessages.email = ['Please enter valid email address.']
      }
      if (!message) {
        inputErrorMessages.message = ['Please enter feedback message.']
      }
      if (!agreePrivacyNotice) {
        inputErrorMessages.agreePrivacyNotice = [
          'Please accept the Privacy notice.',
        ]
      }
      if (!recaptcha) {
        inputErrorMessages.recaptcha = [
          'Verification failed - please try again.',
        ]
        onRecaptchaExpired()
      }

      if (
        !Object.keys(inputErrorMessages).find(
          (key) => inputErrorMessages[key].length
        )
      ) {
        const subject = 'User feedback'
        const body = `Timestamp: ${new Date().toLocaleString()}\n
----
User name: ${name}
----
User email: ${email}
----
Message:
${message}
----
Source page URL:
${pageUrl || 'N/A'}
----
Other page URLS:
${pageUrls.length ? pageUrls.join('\n') : 'N/A'}
----
Agree to join user research: ${joinUserResearch ? 'yes' : 'no'}`
        this.sendingEmail = true
        try {
          await sendFeedback(subject, body, recaptcha, joinUserResearch)
          close({ success: true })
        } catch (e) {
          this.networkErrorMessage = e.message
        } finally {
          this.sendingEmail = false
        }
      }
    },
    close(e) {
      this.$emit('close', e)
    },
    onRecaptchaVerified(response) {
      this.inputs.recaptcha = response
      this.inputErrorMessages.recaptcha = []
    },
    onRecaptchaExpired() {
      this.inputs.recaptchaVerified = false
      this.$refs.submitRecaptcha.reset()
    },
    goToPrivacyNotice() {
      const w = window.open(
        '/PrivacyNoticeForEPMC?type=advanced-user-services',
        'advanced-user-services',
        'scrollbars=no,location=no,menuBar=yes,resizable=yes,status=no,toolbar=yes,width=600,height=800'
      )
      try {
        if (w.blur) w.focus()
      } catch (ignore) {}
    },
  },
}
</script>
<template>
  <div id="new-feedback-form">
    <div id="name-email-input-row" class="input-row">
      <div>
        <label name="name-input" class="semi-bold" for="name-input"
          >Name <span class="required-symbol">*</span></label
        ><br />
        <template v-for="message in inputErrorMessages.name">
          <span :key="message + 'error'" class="error">{{ message }}</span
          ><br :key="message" />
        </template>
        <input id="name-input" v-model.trim="inputs.name" type="text" />
      </div>
      <div>
        <label name="email-input" class="semi-bold" for="email-input"
          >Email address <span class="required-symbol">*</span></label
        ><br />
        <template v-for="message in inputErrorMessages.email">
          <span :key="message + 'error'" class="error">{{ message }}</span
          ><br :key="message" />
        </template>
        <input id="email-input" v-model.trim="inputs.email" type="text" />
      </div>
    </div>

    <div class="input-row">
      <label name="message-input" class="semi-bold" for="message-input"
        >Message <span class="required-symbol">*</span></label
      ><br />
      <template v-for="message in inputErrorMessages.message">
        <span :key="message + 'error'" class="error">{{ message }}</span
        ><br :key="message" />
      </template>
      <textarea
        id="message-input"
        v-model.trim="inputs.message"
        placeholder="Let us know why you are contacting us."
      ></textarea>

      <!-- <div v-if="showAddFiles" id="file-upload-section" class="url-section">
        <ul v-if="inputs.files.length">
          <li v-for="(file, index) in inputs.files" :key="index">
            {{ file.name }} ({{ parseInt(file.size / 1000) }}kb)<action
              :aria-label="'Remove ' + url"
              class="item-action"
              @click.prevent="removeFile(index)"
              ><i slot="icon" class="fas fa-times"
            /></action>
          </li>
        </ul>
        <div @dragover.prevent @drop.prevent>
          <div id="file-zone" @drop="dragFiles">
            <span>Drop screenshots to here, or</span>
            <button
              class="secondary"
              @click.prevent="onUploadFilesButtonClicked"
            >
              Select screenshots
            </button>
            <div
              v-for="message in inputErrorMessages.files"
              :key="message"
              class="error"
            >
              {{ message }}
            </div>
          </div>
          <input
            id="upload-file-button"
            type="file"
            multiple
            @change="uploadFiles"
          />
        </div>
      </div> -->

      <div v-if="showAddPageUrls" class="url-section">
        <ul v-if="inputs.pageUrls.length">
          <li v-for="(url, index) in inputs.pageUrls" :key="index">
            {{ url
            }}<action
              :aria-label="'Remove ' + url"
              class="item-action"
              @click.prevent="removeUrl(index)"
              ><i slot="icon" class="fas fa-times"
            /></action>
          </li>
        </ul>
        <div>
          <span id="page-url-input-group">
            <label for="page-url-input">URL:</label>&nbsp;<input
              id="page-url-input"
              type="text"
              :class="{ disabled: inputErrorMessages.pageUrls.length }"
              placeholder="http(s)://"
              @keyup.enter="addPageUrl"
            /><input
              id="add-url-button"
              type="button"
              value="Add"
              :class="[
                'secondary',
                { disabled: inputErrorMessages.pageUrls.length },
              ]"
              @click.prevent="addPageUrl"
            />
          </span>
          <div
            v-for="message in inputErrorMessages.pageUrls"
            :key="message"
            class="error"
          >
            {{ message }}
          </div>
        </div>
      </div>

      <div>
        <!-- <action
          v-if="!showAddFiles"
          id="add-screenshot-button"
          class="add-btn"
          @click.prevent="showAddFiles = true"
          ><i slot="icon" class="fas fa-plus"></i>Add a screenshot</action
        > -->
        <action
          v-if="!showAddPageUrls"
          class="add-btn"
          @click.prevent="showAddPageUrls = true"
          ><i slot="icon" class="fas fa-plus"></i>Add a page URL (if different
          from the page you are on)</action
        >
      </div>
    </div>

    <div class="input-row">
      <label class="semi-bold"
        >Would you like to provide further feedback by participating in user
        research?</label
      ><br />
      <input
        id="join-user-research-yes"
        v-model="inputs.joinUserResearch"
        name="join-user-research"
        type="radio"
        :value="true"
        @keyup.enter="inputs.joinUserResearch = true"
      /><label for="join-user-research-yes">Yes</label>
      <input
        id="join-user-research-no"
        v-model="inputs.joinUserResearch"
        name="join-user-research"
        type="radio"
        :value="false"
        @keyup.enter="inputs.joinUserResearch = false"
      /><label for="join-user-research-no">No</label>
    </div>

    <div class="input-row">
      <input
        id="agree-privacy-notice"
        v-model="inputs.agreePrivacyNotice"
        type="checkbox"
        @keyup.enter="inputs.agreePrivacyNotice = !inputs.agreePrivacyNotice"
      /><label for="agree-privacy-notice"
        >I agree to the limited use of my personal data as described in this
        <a
          href="/PrivacyNoticeForEPMC?type=advanced-user-services"
          target="_blank"
          @click.prevent="goToPrivacyNotice"
          >Privacy Notice</a
        >. <span class="required-symbol">*</span></label
      >
      <template v-for="message in inputErrorMessages.agreePrivacyNotice">
        <br :key="message" /><span :key="message + 'error'" class="error">{{
          message
        }}</span>
      </template>
    </div>

    <div class="input-row">
      <vue-recaptcha
        ref="submitRecaptcha"
        sitekey="6Ldb8sMUAAAAACGnl-_JQOpBmUGFkavmFduMT6_D"
        badge="inline"
        @verify="onRecaptchaVerified"
        @error="onRecaptchaExpired"
        @expire="onRecaptchaExpired"
      ></vue-recaptcha>
      <span
        v-for="message in inputErrorMessages.recaptcha"
        :key="message + 'error'"
        class="error"
        >{{ message }}</span
      >
    </div>

    <div class="input-row">
      <input
        type="button"
        value="Submit"
        :class="{ disabled: sendingEmail }"
        @click="submit"
      />
      <input
        type="button"
        class="secondary"
        value="Cancel"
        @click.prevent="close"
      />
      <template v-if="networkErrorMessage">
        <br />
        <span class="error">{{ networkErrorMessage }}</span>
      </template>
    </div>
    <loading v-if="sendingEmail" />
  </div>
</template>
<style scoped lang="scss">
#new-feedback-form {
  .input-row {
    &:not(:last-child) {
      margin-bottom: $base-unit * 6;
    }

    &#name-email-input-row {
      display: flex;
      justify-content: space-between;
      div {
        width: 100%;
        &:not(:last-child) {
          margin-right: $base-unit * 4;
        }
      }
      @media only screen and (max-width: $breakpoint-extra-small) {
        display: block;
        div {
          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: $base-unit * 4;
          }
        }
      }
    }

    .required-symbol,
    .error {
      color: $epmc-red;
    }

    input[type='text'] {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: $base-unit * 32;
    }

    .url-section {
      border-left: thin solid lighten($epmc-darker-grey, 45%);
      border-bottom: thin solid lighten($epmc-darker-grey, 45%);
      border-right: thin solid lighten($epmc-darker-grey, 45%);
      padding: $base-unit * 2;
      &:first-of-type {
        margin-top: $base-unit * -1.75;
      }
      #page-url-input-group {
        display: flex;
        align-items: center;
        input[type='text'] {
          flex-grow: 2;
        }
      }
      /*#file-zone {
        width: 100%;
        min-height: $base-unit * 24;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        button {
          width: fit-content;
        }
      }*/
      /*#upload-file-button {
        display: none;
      }*/
      #page-url-input {
        width: auto;
        height: $base-unit * 8;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        .item-action {
          margin-left: $base-unit;
        }
      }
    }

    #add-screenshot-button {
      margin-right: $base-unit * 4;
    }
    #add-url-button {
      min-width: 0;
      height: $base-unit * 8;
      border-radius: 0;
      padding: 0 ($base-unit * 2);
    }
    .add-btn {
      display: inline-block;
    }
    label[for='join-user-research-yes'] {
      margin-right: $base-unit * 4;
    }
    input[value='Submit'] {
      margin-right: $base-unit * 4;
    }
  }
}
</style>
